/* @tailwind base; */
@tailwind components;
@tailwind utilities;

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-shadow: none;
}

.galleryContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
